import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _37afa86e = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _8f11fc4c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _5c207818 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3f75984c = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _0baf57f1 = () => interopDefault(import('../pages/indicators/index.vue' /* webpackChunkName: "pages/indicators/index" */))
const _aa076580 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6c4b14e8 = () => interopDefault(import('../pages/not-auth.vue' /* webpackChunkName: "pages/not-auth" */))
const _cdd265e6 = () => interopDefault(import('../pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _70e91dc8 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _3b487d24 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _8051af54 = () => interopDefault(import('../pages/sectors/index.vue' /* webpackChunkName: "pages/sectors/index" */))
const _3f477ce0 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _61c01bd3 = () => interopDefault(import('../pages/system-log/index.vue' /* webpackChunkName: "pages/system-log/index" */))
const _7a11335e = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _73581566 = () => interopDefault(import('../pages/admins/permissions/index.vue' /* webpackChunkName: "pages/admins/permissions/index" */))
const _11f4fb2a = () => interopDefault(import('../pages/admins/users/index.vue' /* webpackChunkName: "pages/admins/users/index" */))
const _571ee13e = () => interopDefault(import('../pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _67a87cec = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _bddb0f34 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _2a47a530 = () => interopDefault(import('../pages/auth/verification.vue' /* webpackChunkName: "pages/auth/verification" */))
const _6b148a8b = () => interopDefault(import('../pages/calendar/profits/index.vue' /* webpackChunkName: "pages/calendar/profits/index" */))
const _21d437fc = () => interopDefault(import('../pages/calendar/stock-divinant/index.vue' /* webpackChunkName: "pages/calendar/stock-divinant/index" */))
const _1a32f952 = () => interopDefault(import('../pages/calendar/subscriptions/index.vue' /* webpackChunkName: "pages/calendar/subscriptions/index" */))
const _26920c5b = () => interopDefault(import('../pages/categories/add/index.vue' /* webpackChunkName: "pages/categories/add/index" */))
const _3a565ba4 = () => interopDefault(import('../pages/companies-management/companies/index.vue' /* webpackChunkName: "pages/companies-management/companies/index" */))
const _10b10919 = () => interopDefault(import('../pages/companies-management/companies-indicators/index.vue' /* webpackChunkName: "pages/companies-management/companies-indicators/index" */))
const _3744e667 = () => interopDefault(import('../pages/companies-management/indicators/index.vue' /* webpackChunkName: "pages/companies-management/indicators/index" */))
const _57175556 = () => interopDefault(import('../pages/companies-management/mainMarket-prices/index.vue' /* webpackChunkName: "pages/companies-management/mainMarket-prices/index" */))
const _1579f3aa = () => interopDefault(import('../pages/companies-management/marketInfo-sum/index.vue' /* webpackChunkName: "pages/companies-management/marketInfo-sum/index" */))
const _05de1ab4 = () => interopDefault(import('../pages/companies-management/research-firms/index.vue' /* webpackChunkName: "pages/companies-management/research-firms/index" */))
const _41886b76 = () => interopDefault(import('../pages/home/features/index.vue' /* webpackChunkName: "pages/home/features/index" */))
const _794d241a = () => interopDefault(import('../pages/home/hero/index.vue' /* webpackChunkName: "pages/home/hero/index" */))
const _6166ef2d = () => interopDefault(import('../pages/home/testimonials/index.vue' /* webpackChunkName: "pages/home/testimonials/index" */))
const _46b1e408 = () => interopDefault(import('../pages/home/why-us/index.vue' /* webpackChunkName: "pages/home/why-us/index" */))
const _be5236fa = () => interopDefault(import('../pages/indicators/add/index.vue' /* webpackChunkName: "pages/indicators/add/index" */))
const _5de68326 = () => interopDefault(import('../pages/marketing/coupons/index.vue' /* webpackChunkName: "pages/marketing/coupons/index" */))
const _3afa9a52 = () => interopDefault(import('../pages/news/add/index.vue' /* webpackChunkName: "pages/news/add/index" */))
const _4afbab81 = () => interopDefault(import('../pages/notifications/apps-notifications/index.vue' /* webpackChunkName: "pages/notifications/apps-notifications/index" */))
const _25280c0b = () => interopDefault(import('../pages/notifications/emails/index.vue' /* webpackChunkName: "pages/notifications/emails/index" */))
const _68243836 = () => interopDefault(import('../pages/reviews/add/index.vue' /* webpackChunkName: "pages/reviews/add/index" */))
const _6225fa68 = () => interopDefault(import('../pages/sectors/add/index.vue' /* webpackChunkName: "pages/sectors/add/index" */))
const _b86df872 = () => interopDefault(import('../pages/sectors/items/index.vue' /* webpackChunkName: "pages/sectors/items/index" */))
const _1daab053 = () => interopDefault(import('../pages/subscriptions/payments/index.vue' /* webpackChunkName: "pages/subscriptions/payments/index" */))
const _5680d29a = () => interopDefault(import('../pages/subscriptions/plans/index.vue' /* webpackChunkName: "pages/subscriptions/plans/index" */))
const _1dad18b8 = () => interopDefault(import('../pages/subscriptions/users/index.vue' /* webpackChunkName: "pages/subscriptions/users/index" */))
const _3eb0c2e3 = () => interopDefault(import('../pages/users/add/index.vue' /* webpackChunkName: "pages/users/add/index" */))
const _6249df78 = () => interopDefault(import('../pages/admins/permissions/add/index.vue' /* webpackChunkName: "pages/admins/permissions/add/index" */))
const _0e937988 = () => interopDefault(import('../pages/admins/users/add/index.vue' /* webpackChunkName: "pages/admins/users/add/index" */))
const _e2658bc6 = () => interopDefault(import('../pages/calendar/profits/add/index.vue' /* webpackChunkName: "pages/calendar/profits/add/index" */))
const _17070014 = () => interopDefault(import('../pages/calendar/stock-divinant/add/index.vue' /* webpackChunkName: "pages/calendar/stock-divinant/add/index" */))
const _d00e2538 = () => interopDefault(import('../pages/calendar/subscriptions/add/index.vue' /* webpackChunkName: "pages/calendar/subscriptions/add/index" */))
const _180af840 = () => interopDefault(import('../pages/companies-management/companies/add/index.vue' /* webpackChunkName: "pages/companies-management/companies/add/index" */))
const _5a568fe7 = () => interopDefault(import('../pages/companies-management/mainMarket-prices/add/index.vue' /* webpackChunkName: "pages/companies-management/mainMarket-prices/add/index" */))
const _2014e5bd = () => interopDefault(import('../pages/companies-management/marketInfo-sum/add/index.vue' /* webpackChunkName: "pages/companies-management/marketInfo-sum/add/index" */))
const _5540ad88 = () => interopDefault(import('../pages/home/features/add/index.vue' /* webpackChunkName: "pages/home/features/add/index" */))
const _ce58aa0e = () => interopDefault(import('../pages/home/hero/edit.vue' /* webpackChunkName: "pages/home/hero/edit" */))
const _3ad41a7f = () => interopDefault(import('../pages/home/testimonials/edit.vue' /* webpackChunkName: "pages/home/testimonials/edit" */))
const _06853460 = () => interopDefault(import('../pages/home/why-us/edit.vue' /* webpackChunkName: "pages/home/why-us/edit" */))
const _13e4acff = () => interopDefault(import('../pages/marketing/coupons/add/index.vue' /* webpackChunkName: "pages/marketing/coupons/add/index" */))
const _0c2a954e = () => interopDefault(import('../pages/sectors/items/add/index.vue' /* webpackChunkName: "pages/sectors/items/add/index" */))
const _48ae3aa8 = () => interopDefault(import('../pages/subscriptions/plans/add/index.vue' /* webpackChunkName: "pages/subscriptions/plans/add/index" */))
const _b816a44c = () => interopDefault(import('../pages/companies-management/companies/financial-file/items-details/edit/_id.vue' /* webpackChunkName: "pages/companies-management/companies/financial-file/items-details/edit/_id" */))
const _b277d85e = () => interopDefault(import('../pages/admins/permissions/edit/_id.vue' /* webpackChunkName: "pages/admins/permissions/edit/_id" */))
const _04a1d528 = () => interopDefault(import('../pages/admins/permissions/view/_id.vue' /* webpackChunkName: "pages/admins/permissions/view/_id" */))
const _c9cbb0e6 = () => interopDefault(import('../pages/admins/users/edit/_id.vue' /* webpackChunkName: "pages/admins/users/edit/_id" */))
const _1bf5adb0 = () => interopDefault(import('../pages/admins/users/view/_id.vue' /* webpackChunkName: "pages/admins/users/view/_id" */))
const _0a6f5ee8 = () => interopDefault(import('../pages/calendar/profits/edit/_id.vue' /* webpackChunkName: "pages/calendar/profits/edit/_id" */))
const _d85b2096 = () => interopDefault(import('../pages/calendar/stock-divinant/edit/_id.vue' /* webpackChunkName: "pages/calendar/stock-divinant/edit/_id" */))
const _7d74cf36 = () => interopDefault(import('../pages/calendar/subscriptions/edit/_id.vue' /* webpackChunkName: "pages/calendar/subscriptions/edit/_id" */))
const _e8ce7bee = () => interopDefault(import('../pages/companies-management/companies/edit/_id.vue' /* webpackChunkName: "pages/companies-management/companies/edit/_id" */))
const _68ee0626 = () => interopDefault(import('../pages/companies-management/companies/expectation-revenue-eps/_id.vue' /* webpackChunkName: "pages/companies-management/companies/expectation-revenue-eps/_id" */))
const _d1e36cdc = () => interopDefault(import('../pages/companies-management/companies/expectation-revenue/_id.vue' /* webpackChunkName: "pages/companies-management/companies/expectation-revenue/_id" */))
const _bdb32982 = () => interopDefault(import('../pages/companies-management/companies/financial-file/_id.vue' /* webpackChunkName: "pages/companies-management/companies/financial-file/_id" */))
const _6cfe2b0c = () => interopDefault(import('../pages/companies-management/companies/target-price/_id.vue' /* webpackChunkName: "pages/companies-management/companies/target-price/_id" */))
const _3af878b8 = () => interopDefault(import('../pages/companies-management/companies/view/_id.vue' /* webpackChunkName: "pages/companies-management/companies/view/_id" */))
const _7d381982 = () => interopDefault(import('../pages/companies-management/mainMarket-prices/edit/_id.vue' /* webpackChunkName: "pages/companies-management/mainMarket-prices/edit/_id" */))
const _520cb0ec = () => interopDefault(import('../pages/companies-management/marketInfo-sum/edit/_id.vue' /* webpackChunkName: "pages/companies-management/marketInfo-sum/edit/_id" */))
const _11b343c1 = () => interopDefault(import('../pages/home/features/edit/_id.vue' /* webpackChunkName: "pages/home/features/edit/_id" */))
const _689e455c = () => interopDefault(import('../pages/home/features/view/_id.vue' /* webpackChunkName: "pages/home/features/view/_id" */))
const _4965f96a = () => interopDefault(import('../pages/marketing/coupons/edit/_id.vue' /* webpackChunkName: "pages/marketing/coupons/edit/_id" */))
const _3700a66a = () => interopDefault(import('../pages/marketing/coupons/statics/_id.vue' /* webpackChunkName: "pages/marketing/coupons/statics/_id" */))
const _bf5e09f6 = () => interopDefault(import('../pages/marketing/coupons/view/_id.vue' /* webpackChunkName: "pages/marketing/coupons/view/_id" */))
const _beec1142 = () => interopDefault(import('../pages/subscriptions/payments/view/_id.vue' /* webpackChunkName: "pages/subscriptions/payments/view/_id" */))
const _689dda1d = () => interopDefault(import('../pages/subscriptions/plans/edit/_id.vue' /* webpackChunkName: "pages/subscriptions/plans/edit/_id" */))
const _80ee4890 = () => interopDefault(import('../pages/subscriptions/plans/view/_id.vue' /* webpackChunkName: "pages/subscriptions/plans/view/_id" */))
const _63bd82e4 = () => interopDefault(import('../pages/categories/edit/_id.vue' /* webpackChunkName: "pages/categories/edit/_id" */))
const _250c4029 = () => interopDefault(import('../pages/categories/view/_id.vue' /* webpackChunkName: "pages/categories/view/_id" */))
const _8d667d34 = () => interopDefault(import('../pages/indicators/edit/_id.vue' /* webpackChunkName: "pages/indicators/edit/_id" */))
const _1037c301 = () => interopDefault(import('../pages/indicators/view/_id.vue' /* webpackChunkName: "pages/indicators/view/_id" */))
const _93f8d492 = () => interopDefault(import('../pages/news/edit/_id.vue' /* webpackChunkName: "pages/news/edit/_id" */))
const _0cee9752 = () => interopDefault(import('../pages/news/view/_id.vue' /* webpackChunkName: "pages/news/view/_id" */))
const _13c1e6ca = () => interopDefault(import('../pages/pages/edit/_id.vue' /* webpackChunkName: "pages/pages/edit/_id" */))
const _6aace865 = () => interopDefault(import('../pages/pages/view/_id.vue' /* webpackChunkName: "pages/pages/view/_id" */))
const _7006a95a = () => interopDefault(import('../pages/reviews/edit/_id.vue' /* webpackChunkName: "pages/reviews/edit/_id" */))
const _c2fe4a3e = () => interopDefault(import('../pages/sectors/edit/_id.vue' /* webpackChunkName: "pages/sectors/edit/_id" */))
const _638d9706 = () => interopDefault(import('../pages/users/edit/_id.vue' /* webpackChunkName: "pages/users/edit/_id" */))
const _436f2525 = () => interopDefault(import('../pages/users/plan/_id.vue' /* webpackChunkName: "pages/users/plan/_id" */))
const _8b0ecebe = () => interopDefault(import('../pages/users/view/_id.vue' /* webpackChunkName: "pages/users/view/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/categories",
    component: _37afa86e,
    name: "categories___ar"
  }, {
    path: "/dashboard",
    component: _8f11fc4c,
    name: "dashboard___ar"
  }, {
    path: "/en",
    component: _5c207818,
    name: "index___en"
  }, {
    path: "/home",
    component: _3f75984c,
    name: "home___ar"
  }, {
    path: "/indicators",
    component: _0baf57f1,
    name: "indicators___ar"
  }, {
    path: "/news",
    component: _aa076580,
    name: "news___ar"
  }, {
    path: "/not-auth",
    component: _6c4b14e8,
    name: "not-auth___ar"
  }, {
    path: "/pages",
    component: _cdd265e6,
    name: "pages___ar"
  }, {
    path: "/reports",
    component: _70e91dc8,
    name: "reports___ar"
  }, {
    path: "/reviews",
    component: _3b487d24,
    name: "reviews___ar"
  }, {
    path: "/sectors",
    component: _8051af54,
    name: "sectors___ar"
  }, {
    path: "/settings",
    component: _3f477ce0,
    name: "settings___ar"
  }, {
    path: "/system-log",
    component: _61c01bd3,
    name: "system-log___ar"
  }, {
    path: "/users",
    component: _7a11335e,
    name: "users___ar"
  }, {
    path: "/admins/permissions",
    component: _73581566,
    name: "admins-permissions___ar"
  }, {
    path: "/admins/users",
    component: _11f4fb2a,
    name: "admins-users___ar"
  }, {
    path: "/auth/forget-password",
    component: _571ee13e,
    name: "auth-forget-password___ar"
  }, {
    path: "/auth/login",
    component: _67a87cec,
    name: "auth-login___ar"
  }, {
    path: "/auth/reset-password",
    component: _bddb0f34,
    name: "auth-reset-password___ar"
  }, {
    path: "/auth/verification",
    component: _2a47a530,
    name: "auth-verification___ar"
  }, {
    path: "/calendar/profits",
    component: _6b148a8b,
    name: "calendar-profits___ar"
  }, {
    path: "/calendar/stock-divinant",
    component: _21d437fc,
    name: "calendar-stock-divinant___ar"
  }, {
    path: "/calendar/subscriptions",
    component: _1a32f952,
    name: "calendar-subscriptions___ar"
  }, {
    path: "/categories/add",
    component: _26920c5b,
    name: "categories-add___ar"
  }, {
    path: "/companies-management/companies",
    component: _3a565ba4,
    name: "companies-management-companies___ar"
  }, {
    path: "/companies-management/companies-indicators",
    component: _10b10919,
    name: "companies-management-companies-indicators___ar"
  }, {
    path: "/companies-management/indicators",
    component: _3744e667,
    name: "companies-management-indicators___ar"
  }, {
    path: "/companies-management/mainMarket-prices",
    component: _57175556,
    name: "companies-management-mainMarket-prices___ar"
  }, {
    path: "/companies-management/marketInfo-sum",
    component: _1579f3aa,
    name: "companies-management-marketInfo-sum___ar"
  }, {
    path: "/companies-management/research-firms",
    component: _05de1ab4,
    name: "companies-management-research-firms___ar"
  }, {
    path: "/en/categories",
    component: _37afa86e,
    name: "categories___en"
  }, {
    path: "/en/dashboard",
    component: _8f11fc4c,
    name: "dashboard___en"
  }, {
    path: "/en/home",
    component: _3f75984c,
    name: "home___en"
  }, {
    path: "/en/indicators",
    component: _0baf57f1,
    name: "indicators___en"
  }, {
    path: "/en/news",
    component: _aa076580,
    name: "news___en"
  }, {
    path: "/en/not-auth",
    component: _6c4b14e8,
    name: "not-auth___en"
  }, {
    path: "/en/pages",
    component: _cdd265e6,
    name: "pages___en"
  }, {
    path: "/en/reports",
    component: _70e91dc8,
    name: "reports___en"
  }, {
    path: "/en/reviews",
    component: _3b487d24,
    name: "reviews___en"
  }, {
    path: "/en/sectors",
    component: _8051af54,
    name: "sectors___en"
  }, {
    path: "/en/settings",
    component: _3f477ce0,
    name: "settings___en"
  }, {
    path: "/en/system-log",
    component: _61c01bd3,
    name: "system-log___en"
  }, {
    path: "/en/users",
    component: _7a11335e,
    name: "users___en"
  }, {
    path: "/home/features",
    component: _41886b76,
    name: "home-features___ar"
  }, {
    path: "/home/hero",
    component: _794d241a,
    name: "home-hero___ar"
  }, {
    path: "/home/testimonials",
    component: _6166ef2d,
    name: "home-testimonials___ar"
  }, {
    path: "/home/why-us",
    component: _46b1e408,
    name: "home-why-us___ar"
  }, {
    path: "/indicators/add",
    component: _be5236fa,
    name: "indicators-add___ar"
  }, {
    path: "/marketing/coupons",
    component: _5de68326,
    name: "marketing-coupons___ar"
  }, {
    path: "/news/add",
    component: _3afa9a52,
    name: "news-add___ar"
  }, {
    path: "/notifications/apps-notifications",
    component: _4afbab81,
    name: "notifications-apps-notifications___ar"
  }, {
    path: "/notifications/emails",
    component: _25280c0b,
    name: "notifications-emails___ar"
  }, {
    path: "/reviews/add",
    component: _68243836,
    name: "reviews-add___ar"
  }, {
    path: "/sectors/add",
    component: _6225fa68,
    name: "sectors-add___ar"
  }, {
    path: "/sectors/items",
    component: _b86df872,
    name: "sectors-items___ar"
  }, {
    path: "/subscriptions/payments",
    component: _1daab053,
    name: "subscriptions-payments___ar"
  }, {
    path: "/subscriptions/plans",
    component: _5680d29a,
    name: "subscriptions-plans___ar"
  }, {
    path: "/subscriptions/users",
    component: _1dad18b8,
    name: "subscriptions-users___ar"
  }, {
    path: "/users/add",
    component: _3eb0c2e3,
    name: "users-add___ar"
  }, {
    path: "/admins/permissions/add",
    component: _6249df78,
    name: "admins-permissions-add___ar"
  }, {
    path: "/admins/users/add",
    component: _0e937988,
    name: "admins-users-add___ar"
  }, {
    path: "/calendar/profits/add",
    component: _e2658bc6,
    name: "calendar-profits-add___ar"
  }, {
    path: "/calendar/stock-divinant/add",
    component: _17070014,
    name: "calendar-stock-divinant-add___ar"
  }, {
    path: "/calendar/subscriptions/add",
    component: _d00e2538,
    name: "calendar-subscriptions-add___ar"
  }, {
    path: "/companies-management/companies/add",
    component: _180af840,
    name: "companies-management-companies-add___ar"
  }, {
    path: "/companies-management/mainMarket-prices/add",
    component: _5a568fe7,
    name: "companies-management-mainMarket-prices-add___ar"
  }, {
    path: "/companies-management/marketInfo-sum/add",
    component: _2014e5bd,
    name: "companies-management-marketInfo-sum-add___ar"
  }, {
    path: "/en/admins/permissions",
    component: _73581566,
    name: "admins-permissions___en"
  }, {
    path: "/en/admins/users",
    component: _11f4fb2a,
    name: "admins-users___en"
  }, {
    path: "/en/auth/forget-password",
    component: _571ee13e,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _67a87cec,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset-password",
    component: _bddb0f34,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/verification",
    component: _2a47a530,
    name: "auth-verification___en"
  }, {
    path: "/en/calendar/profits",
    component: _6b148a8b,
    name: "calendar-profits___en"
  }, {
    path: "/en/calendar/stock-divinant",
    component: _21d437fc,
    name: "calendar-stock-divinant___en"
  }, {
    path: "/en/calendar/subscriptions",
    component: _1a32f952,
    name: "calendar-subscriptions___en"
  }, {
    path: "/en/categories/add",
    component: _26920c5b,
    name: "categories-add___en"
  }, {
    path: "/en/companies-management/companies",
    component: _3a565ba4,
    name: "companies-management-companies___en"
  }, {
    path: "/en/companies-management/companies-indicators",
    component: _10b10919,
    name: "companies-management-companies-indicators___en"
  }, {
    path: "/en/companies-management/indicators",
    component: _3744e667,
    name: "companies-management-indicators___en"
  }, {
    path: "/en/companies-management/mainMarket-prices",
    component: _57175556,
    name: "companies-management-mainMarket-prices___en"
  }, {
    path: "/en/companies-management/marketInfo-sum",
    component: _1579f3aa,
    name: "companies-management-marketInfo-sum___en"
  }, {
    path: "/en/companies-management/research-firms",
    component: _05de1ab4,
    name: "companies-management-research-firms___en"
  }, {
    path: "/en/home/features",
    component: _41886b76,
    name: "home-features___en"
  }, {
    path: "/en/home/hero",
    component: _794d241a,
    name: "home-hero___en"
  }, {
    path: "/en/home/testimonials",
    component: _6166ef2d,
    name: "home-testimonials___en"
  }, {
    path: "/en/home/why-us",
    component: _46b1e408,
    name: "home-why-us___en"
  }, {
    path: "/en/indicators/add",
    component: _be5236fa,
    name: "indicators-add___en"
  }, {
    path: "/en/marketing/coupons",
    component: _5de68326,
    name: "marketing-coupons___en"
  }, {
    path: "/en/news/add",
    component: _3afa9a52,
    name: "news-add___en"
  }, {
    path: "/en/notifications/apps-notifications",
    component: _4afbab81,
    name: "notifications-apps-notifications___en"
  }, {
    path: "/en/notifications/emails",
    component: _25280c0b,
    name: "notifications-emails___en"
  }, {
    path: "/en/reviews/add",
    component: _68243836,
    name: "reviews-add___en"
  }, {
    path: "/en/sectors/add",
    component: _6225fa68,
    name: "sectors-add___en"
  }, {
    path: "/en/sectors/items",
    component: _b86df872,
    name: "sectors-items___en"
  }, {
    path: "/en/subscriptions/payments",
    component: _1daab053,
    name: "subscriptions-payments___en"
  }, {
    path: "/en/subscriptions/plans",
    component: _5680d29a,
    name: "subscriptions-plans___en"
  }, {
    path: "/en/subscriptions/users",
    component: _1dad18b8,
    name: "subscriptions-users___en"
  }, {
    path: "/en/users/add",
    component: _3eb0c2e3,
    name: "users-add___en"
  }, {
    path: "/home/features/add",
    component: _5540ad88,
    name: "home-features-add___ar"
  }, {
    path: "/home/hero/edit",
    component: _ce58aa0e,
    name: "home-hero-edit___ar"
  }, {
    path: "/home/testimonials/edit",
    component: _3ad41a7f,
    name: "home-testimonials-edit___ar"
  }, {
    path: "/home/why-us/edit",
    component: _06853460,
    name: "home-why-us-edit___ar"
  }, {
    path: "/marketing/coupons/add",
    component: _13e4acff,
    name: "marketing-coupons-add___ar"
  }, {
    path: "/sectors/items/add",
    component: _0c2a954e,
    name: "sectors-items-add___ar"
  }, {
    path: "/subscriptions/plans/add",
    component: _48ae3aa8,
    name: "subscriptions-plans-add___ar"
  }, {
    path: "/en/admins/permissions/add",
    component: _6249df78,
    name: "admins-permissions-add___en"
  }, {
    path: "/en/admins/users/add",
    component: _0e937988,
    name: "admins-users-add___en"
  }, {
    path: "/en/calendar/profits/add",
    component: _e2658bc6,
    name: "calendar-profits-add___en"
  }, {
    path: "/en/calendar/stock-divinant/add",
    component: _17070014,
    name: "calendar-stock-divinant-add___en"
  }, {
    path: "/en/calendar/subscriptions/add",
    component: _d00e2538,
    name: "calendar-subscriptions-add___en"
  }, {
    path: "/en/companies-management/companies/add",
    component: _180af840,
    name: "companies-management-companies-add___en"
  }, {
    path: "/en/companies-management/mainMarket-prices/add",
    component: _5a568fe7,
    name: "companies-management-mainMarket-prices-add___en"
  }, {
    path: "/en/companies-management/marketInfo-sum/add",
    component: _2014e5bd,
    name: "companies-management-marketInfo-sum-add___en"
  }, {
    path: "/en/home/features/add",
    component: _5540ad88,
    name: "home-features-add___en"
  }, {
    path: "/en/home/hero/edit",
    component: _ce58aa0e,
    name: "home-hero-edit___en"
  }, {
    path: "/en/home/testimonials/edit",
    component: _3ad41a7f,
    name: "home-testimonials-edit___en"
  }, {
    path: "/en/home/why-us/edit",
    component: _06853460,
    name: "home-why-us-edit___en"
  }, {
    path: "/en/marketing/coupons/add",
    component: _13e4acff,
    name: "marketing-coupons-add___en"
  }, {
    path: "/en/sectors/items/add",
    component: _0c2a954e,
    name: "sectors-items-add___en"
  }, {
    path: "/en/subscriptions/plans/add",
    component: _48ae3aa8,
    name: "subscriptions-plans-add___en"
  }, {
    path: "/",
    component: _5c207818,
    name: "index___ar"
  }, {
    path: "/en/companies-management/companies/financial-file/items-details/edit/:id?",
    component: _b816a44c,
    name: "companies-management-companies-financial-file-items-details-edit-id___en"
  }, {
    path: "/companies-management/companies/financial-file/items-details/edit/:id?",
    component: _b816a44c,
    name: "companies-management-companies-financial-file-items-details-edit-id___ar"
  }, {
    path: "/en/admins/permissions/edit/:id?",
    component: _b277d85e,
    name: "admins-permissions-edit-id___en"
  }, {
    path: "/en/admins/permissions/view/:id?",
    component: _04a1d528,
    name: "admins-permissions-view-id___en"
  }, {
    path: "/en/admins/users/edit/:id?",
    component: _c9cbb0e6,
    name: "admins-users-edit-id___en"
  }, {
    path: "/en/admins/users/view/:id?",
    component: _1bf5adb0,
    name: "admins-users-view-id___en"
  }, {
    path: "/en/calendar/profits/edit/:id?",
    component: _0a6f5ee8,
    name: "calendar-profits-edit-id___en"
  }, {
    path: "/en/calendar/stock-divinant/edit/:id",
    component: _d85b2096,
    name: "calendar-stock-divinant-edit-id___en"
  }, {
    path: "/en/calendar/subscriptions/edit/:id?",
    component: _7d74cf36,
    name: "calendar-subscriptions-edit-id___en"
  }, {
    path: "/en/companies-management/companies/edit/:id",
    component: _e8ce7bee,
    name: "companies-management-companies-edit-id___en"
  }, {
    path: "/en/companies-management/companies/expectation-revenue-eps/:id",
    component: _68ee0626,
    name: "companies-management-companies-expectation-revenue-eps-id___en"
  }, {
    path: "/en/companies-management/companies/expectation-revenue/:id",
    component: _d1e36cdc,
    name: "companies-management-companies-expectation-revenue-id___en"
  }, {
    path: "/en/companies-management/companies/financial-file/:id",
    component: _bdb32982,
    name: "companies-management-companies-financial-file-id___en"
  }, {
    path: "/en/companies-management/companies/target-price/:id",
    component: _6cfe2b0c,
    name: "companies-management-companies-target-price-id___en"
  }, {
    path: "/en/companies-management/companies/view/:id",
    component: _3af878b8,
    name: "companies-management-companies-view-id___en"
  }, {
    path: "/en/companies-management/mainMarket-prices/edit/:id?",
    component: _7d381982,
    name: "companies-management-mainMarket-prices-edit-id___en"
  }, {
    path: "/en/companies-management/marketInfo-sum/edit/:id?",
    component: _520cb0ec,
    name: "companies-management-marketInfo-sum-edit-id___en"
  }, {
    path: "/en/home/features/edit/:id?",
    component: _11b343c1,
    name: "home-features-edit-id___en"
  }, {
    path: "/en/home/features/view/:id?",
    component: _689e455c,
    name: "home-features-view-id___en"
  }, {
    path: "/en/marketing/coupons/edit/:id?",
    component: _4965f96a,
    name: "marketing-coupons-edit-id___en"
  }, {
    path: "/en/marketing/coupons/statics/:id?",
    component: _3700a66a,
    name: "marketing-coupons-statics-id___en"
  }, {
    path: "/en/marketing/coupons/view/:id?",
    component: _bf5e09f6,
    name: "marketing-coupons-view-id___en"
  }, {
    path: "/en/subscriptions/payments/view/:id?",
    component: _beec1142,
    name: "subscriptions-payments-view-id___en"
  }, {
    path: "/en/subscriptions/plans/edit/:id?",
    component: _689dda1d,
    name: "subscriptions-plans-edit-id___en"
  }, {
    path: "/en/subscriptions/plans/view/:id?",
    component: _80ee4890,
    name: "subscriptions-plans-view-id___en"
  }, {
    path: "/admins/permissions/edit/:id?",
    component: _b277d85e,
    name: "admins-permissions-edit-id___ar"
  }, {
    path: "/admins/permissions/view/:id?",
    component: _04a1d528,
    name: "admins-permissions-view-id___ar"
  }, {
    path: "/admins/users/edit/:id?",
    component: _c9cbb0e6,
    name: "admins-users-edit-id___ar"
  }, {
    path: "/admins/users/view/:id?",
    component: _1bf5adb0,
    name: "admins-users-view-id___ar"
  }, {
    path: "/calendar/profits/edit/:id?",
    component: _0a6f5ee8,
    name: "calendar-profits-edit-id___ar"
  }, {
    path: "/calendar/stock-divinant/edit/:id",
    component: _d85b2096,
    name: "calendar-stock-divinant-edit-id___ar"
  }, {
    path: "/calendar/subscriptions/edit/:id?",
    component: _7d74cf36,
    name: "calendar-subscriptions-edit-id___ar"
  }, {
    path: "/companies-management/companies/edit/:id",
    component: _e8ce7bee,
    name: "companies-management-companies-edit-id___ar"
  }, {
    path: "/companies-management/companies/expectation-revenue-eps/:id",
    component: _68ee0626,
    name: "companies-management-companies-expectation-revenue-eps-id___ar"
  }, {
    path: "/companies-management/companies/expectation-revenue/:id",
    component: _d1e36cdc,
    name: "companies-management-companies-expectation-revenue-id___ar"
  }, {
    path: "/companies-management/companies/financial-file/:id",
    component: _bdb32982,
    name: "companies-management-companies-financial-file-id___ar"
  }, {
    path: "/companies-management/companies/target-price/:id",
    component: _6cfe2b0c,
    name: "companies-management-companies-target-price-id___ar"
  }, {
    path: "/companies-management/companies/view/:id",
    component: _3af878b8,
    name: "companies-management-companies-view-id___ar"
  }, {
    path: "/companies-management/mainMarket-prices/edit/:id?",
    component: _7d381982,
    name: "companies-management-mainMarket-prices-edit-id___ar"
  }, {
    path: "/companies-management/marketInfo-sum/edit/:id?",
    component: _520cb0ec,
    name: "companies-management-marketInfo-sum-edit-id___ar"
  }, {
    path: "/en/categories/edit/:id?",
    component: _63bd82e4,
    name: "categories-edit-id___en"
  }, {
    path: "/en/categories/view/:id?",
    component: _250c4029,
    name: "categories-view-id___en"
  }, {
    path: "/en/indicators/edit/:id?",
    component: _8d667d34,
    name: "indicators-edit-id___en"
  }, {
    path: "/en/indicators/view/:id?",
    component: _1037c301,
    name: "indicators-view-id___en"
  }, {
    path: "/en/news/edit/:id?",
    component: _93f8d492,
    name: "news-edit-id___en"
  }, {
    path: "/en/news/view/:id?",
    component: _0cee9752,
    name: "news-view-id___en"
  }, {
    path: "/en/pages/edit/:id?",
    component: _13c1e6ca,
    name: "pages-edit-id___en"
  }, {
    path: "/en/pages/view/:id?",
    component: _6aace865,
    name: "pages-view-id___en"
  }, {
    path: "/en/reviews/edit/:id?",
    component: _7006a95a,
    name: "reviews-edit-id___en"
  }, {
    path: "/en/sectors/edit/:id?",
    component: _c2fe4a3e,
    name: "sectors-edit-id___en"
  }, {
    path: "/en/users/edit/:id?",
    component: _638d9706,
    name: "users-edit-id___en"
  }, {
    path: "/en/users/plan/:id?",
    component: _436f2525,
    name: "users-plan-id___en"
  }, {
    path: "/en/users/view/:id?",
    component: _8b0ecebe,
    name: "users-view-id___en"
  }, {
    path: "/home/features/edit/:id?",
    component: _11b343c1,
    name: "home-features-edit-id___ar"
  }, {
    path: "/home/features/view/:id?",
    component: _689e455c,
    name: "home-features-view-id___ar"
  }, {
    path: "/marketing/coupons/edit/:id?",
    component: _4965f96a,
    name: "marketing-coupons-edit-id___ar"
  }, {
    path: "/marketing/coupons/statics/:id?",
    component: _3700a66a,
    name: "marketing-coupons-statics-id___ar"
  }, {
    path: "/marketing/coupons/view/:id?",
    component: _bf5e09f6,
    name: "marketing-coupons-view-id___ar"
  }, {
    path: "/subscriptions/payments/view/:id?",
    component: _beec1142,
    name: "subscriptions-payments-view-id___ar"
  }, {
    path: "/subscriptions/plans/edit/:id?",
    component: _689dda1d,
    name: "subscriptions-plans-edit-id___ar"
  }, {
    path: "/subscriptions/plans/view/:id?",
    component: _80ee4890,
    name: "subscriptions-plans-view-id___ar"
  }, {
    path: "/categories/edit/:id?",
    component: _63bd82e4,
    name: "categories-edit-id___ar"
  }, {
    path: "/categories/view/:id?",
    component: _250c4029,
    name: "categories-view-id___ar"
  }, {
    path: "/indicators/edit/:id?",
    component: _8d667d34,
    name: "indicators-edit-id___ar"
  }, {
    path: "/indicators/view/:id?",
    component: _1037c301,
    name: "indicators-view-id___ar"
  }, {
    path: "/news/edit/:id?",
    component: _93f8d492,
    name: "news-edit-id___ar"
  }, {
    path: "/news/view/:id?",
    component: _0cee9752,
    name: "news-view-id___ar"
  }, {
    path: "/pages/edit/:id?",
    component: _13c1e6ca,
    name: "pages-edit-id___ar"
  }, {
    path: "/pages/view/:id?",
    component: _6aace865,
    name: "pages-view-id___ar"
  }, {
    path: "/reviews/edit/:id?",
    component: _7006a95a,
    name: "reviews-edit-id___ar"
  }, {
    path: "/sectors/edit/:id?",
    component: _c2fe4a3e,
    name: "sectors-edit-id___ar"
  }, {
    path: "/users/edit/:id?",
    component: _638d9706,
    name: "users-edit-id___ar"
  }, {
    path: "/users/plan/:id?",
    component: _436f2525,
    name: "users-plan-id___ar"
  }, {
    path: "/users/view/:id?",
    component: _8b0ecebe,
    name: "users-view-id___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
